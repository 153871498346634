<template>
    <transition name="fade">
        <div class="modal" v-if="isVisible" :class="sizeClass" tabindex="0">
            <div class="modal__backdrop" @click="this.$emit('close')"></div>
            <transition name="slide">
                <div class="modal__container" v-show="isVisibleContent">
                    <a class="modal__close" @click="this.$emit('close')">
                        <span> Закрыть </span>
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13 1.5L1 13.5M1 1.5L13 13.5" stroke="#AEC1CC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </a>
                    <slot></slot>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
// import isMobile from 'ismobilejs';
export default {
    name: 'PopupModal',
    props: ['size'],
    data: () => ({
        isVisible: false,
        isVisibleContent: false,
        posTop: 0,
    }),
    methods: {
        open () {
            // if(isMobile(window.navigator).any) {
            //     const body = document.querySelectorAll('body')[0];
            //     this.posTop = window.pageYOffset;
            //     body.classList.add('overflow');
            // }
            this.isVisible = true;
            setTimeout(() => {
                this.isVisibleContent = true;
            }, 200);
        },
        close () {
            // if(isMobile(window.navigator).any) {
            //     const body = document.querySelectorAll('body')[0];
            //     body.classList.remove('overflow');
            //     window.scrollBy(0, this.posTop);
            // }
            this.isVisibleContent = false;
            setTimeout(() => {
                this.isVisible = false;
            }, 200);
        },
    },
    computed: {
        sizeClass () {
            return this.size ? 'modal--' + this.size : '';
        },
    }
}

</script>

<style scoped>
    

    
    .slide-enter-active {
      transition: all 0.1s ease-out;
    }
    .slide-leave-active {
      transition: all 0.1s ease-out;
    }
    .slide-leave-to {
      transform: translateY(20px);
      opacity: 0;
    }
    .slide-enter-from {
      transform: translateY(-20px);
      opacity: 0;
    }

    .fade-enter-active {
      transition: all 0.1s ease-out;
    }
    .fade-leave-active {
      transition: all 0.1s ease-out;
    }
    .fade-leave-to {
      opacity: 0;
    }
    .fade-enter-from {
      opacity: 0;
    }




    .modal {
        position: fixed;
        top: 0;
        left: 0;
        height: 100dvh;
        width: 100dvw;
        z-index: 999999;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px;

        display: block;
        text-align: center;
    }

    .modal:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0;
    }

    .modal__backdrop {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 2, .35);
        z-index: 1;
        left: 0px;
        top: 0px;
        position: fixed;
    }

    .modal__container {
        height: 100%;
        width: 100%;
        overflow-y: auto;

        position: relative;
        z-index: 2;
        height: auto;

        border-radius: 8px;
        box-shadow: 0px 10px 30px rgba(11, 40, 54, 0.15);
        background: var(--cozh-white);

        min-width: 300px;
        width: calc(100%);

        display: inline-block;
        position: relative;
        vertical-align: middle;
        text-align: left;
    }




    .modal__close {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 20px;
        z-index: 1;
    }
    .modal__close span {
        margin-top: 2px;
        margin-right: 7px;
        font-size: 11px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        transition: all .2s ease-out;
    }
    .modal__close svg path {
        transition: all .2s ease-out;
    }
    .modal__close:hover span {
    }
    .modal__close:hover svg path {
        stroke: var(--cozh-black);
    }


    .modal--lg .modal__container{
        max-width: 995px;
    }
    .modal--md .modal__container{
        max-width: 700px;
    }
    .modal--xs .modal__container {
        max-width: 310px;
    }

</style>