<template>
  <section class="template">
    <!-- <div class="page" :class="{'page--full': !isLoggedIn}"> -->
      <router-view />
    <!-- </div> -->
  </section>
  
</template>

<script setup>
    // import TopMenu from '@/components/TopMenu.vue';
    // import BottomMenu from '@/components/BottomMenu.vue';
</script>

<script>
    export default {
        name: "MainLayuot",
        created () {},
        computed: {
          isLoggedIn () {
            return this.$store.getters.isLoggedIn
          }
        }
    }
</script>

<style scoped>
  .page {
    padding: 20px;
    width: calc(100% - 90px);
    margin-left: 90px;
    position: relative;
    /*overflow-y: hidden;*/
  }
  .page--full {
    margin-left: 0px;
    width: calc(100%);
  }
  @media (max-width: 767px) {
    .page {
      margin-left: 0px;
      padding: 15px;
      width: 100%;
      /*margin-top: 60px;*/
    }
  }

  footer{
    color: white;
    /* background-color: var(--byskill-purple); */
  }

  footer li {
    list-style: none;
  }

  section .template{
    min-height: 100%;
  }
</style>