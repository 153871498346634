export default {

    toBase64: (file) => {
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
      });
    }, 

    getErrorMessage: (e) => {
      if(e.response.status >= 400 && e.response.status < 500) {
        if(e.response.data && e.response.data.detail && typeof e.response.data.detail === 'string') {
          return e.response.data.detail;
        }
      }
      return 'Произошла ошибка, попробуйте позже';
    },

}