import { h } from 'vue'
import { RouterView } from 'vue-router'
import { createWebHistory, createRouter } from "vue-router";
import store from './store';

// import PageLogin from './pages/PageLogin.vue';
import PageNotFound from './pages/PageNotFound.vue';
import PageLogin from './pages/PageLogin.vue';
import PageSignup from './pages/PageSignup.vue';


import PageCreate from './pages/home/PageCreate.vue';
import PageView from './pages/home/PageView.vue';
import PageHome from './pages/home/PageHome.vue';
import PageEdit from './pages/home/PageEdit.vue';

const routes = [
  
  // Страница 404
  {
    path: '/:catchAll(.*)',
    component: PageNotFound,
    meta: {
      title: '404',
      name: 'Ошибка',
      layout: 'main-layout',
      requiresAuth: false,
    }
  },

    // Страница авторизации
    {
      path: '/login',
      component: { render: () => h(RouterView) },
      meta: {
        title: 'Логин',
        layout: 'empty-layout',
        requiresAuth: false,
      },
      children: [
        { 
          path: '',
          name: 'PageLogin',
          component: PageLogin,
        },
      ]
    },
  
  // Страница каталога
  {
    path: '/signup',
    component: PageSignup,
    meta: {
      title: 'Регистрация',
      name: 'Ошибка',
      layout: 'main-layout',
      requiresAuth: false,
    }
  },

  // Страница каталога
  {
    path: '/lk/create',
    component: PageCreate,
    meta: {
      title: 'Создание мультиссылки',
      name: 'Ошибка',
      layout: 'main-layout',
      requiresAuth: true,
    }
  },

  {
    path: '/lk/edit/:id',
    meta: {
      title: 'Редактирование мультиссылки',
      name: 'Ошибка',
      layout: 'main-layout',
      requiresAuth: true,
    },
    children: [
      { 
        path: '',
        name: 'PageEdit',
        component: PageEdit,
      },
    ]
  },

  // Страница каталога
  {
    path: '/:link',
    component: PageView,
    meta: {
      title: 'Мульти-ссылка',
      name: 'Ошибка',
      layout: 'main-layout',
      requiresAuth: false,
    }
  },


  // Главная страница
  {
    path: '/',
    component: { render: () => h(RouterView) },
    meta: {
      title: 'Boondy.link | Мои мульти-ссылки',
      name: 'Главная',
      ico: 'IconPageMain',
      layout: 'main-layout',
      requiresAuth: false,
    },
    children: [
      { 
        path: '',
        name: 'PageHome',
        component: PageHome,
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  // Устанавливаем title страницы
//   document.title = 'BySkills - ' + to.meta.title;
  document.title = to.meta.title;
  // Если страница закрыта авторизацией
    if(to.matched.some(record => record.meta.requiresAuth)) {
        // Убеждаемся что авторизация есть
        if (store.getters.isLoggedIn) {
        // Пропускаем
        next();
        return;
        }
        // Редиректим на авторизацию
        next('/login');
    } else {
        // Страница без авторизации, пропускаем
        next();
    }
    next();
    return;
});

export default router;