<template>
  <div v-if="isLoad" class="load-container" ><div class="custom-loader"></div></div> 
  <div v-else class="container center">
    <div class="container">
      <div class="main-header">
        <router-link :to="'/'"><h1>Boondy.link</h1></router-link>
        <a class='new-link' @click="logout">Выйти</a>
      </div>
    </div>

      <div class="row main-container">
        <div class="col-md-3 col-xs-12"></div>
        <div class="col-md-6 col-xs-12" style="margin-top: 5px">
          <ImagePicker v-model="video.image" />
          <div class="course-info-fields">
              <!-- Название видео -->
              <div class="field">
                <div class="field__right">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Название"
                    v-model="video.name"
                  />
                </div>
              </div>

              <!-- Динамическое отображение полей для каждой платформы -->
              <div v-for="platform in platforms" :key="platform.id" class="field">
                <div class="field__right">
                  <div
                    class="pl-logo"
                    :style="{
                      backgroundImage: 'url(' + platforms_icon[platform.id] + ')'
                    }"
                  ></div>
                  <span class="pl-logo-name">{{ platform.name }}</span>

                  <!-- Используем v-bind для начального значения и @input для обновления -->
                  <input
                    class="form-control"
                    type="text"
                    :placeholder="'Ссылка на ' + platform.name"
                    :value="getPlatformLink(platform.id)"
                    @input="setPlatformLink(platform.id, $event.target.value)"
                  />
                </div>
              </div>
              <div class="btns">
                  <button class='btn--default' @click="createVideo">
                    <div class="btn-content" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                      <div id='btn-text' class="btn-text">Создать мульти-ссылку</div>
                      <div id='btn-loader' class=""></div>
                    </div>

                  </button>


                  <!-- <button class="btn--default" @click="saveCourse">
                    <div class="btn-content" style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  ">
                      <div id='btn-text' class="btn-text">Сохранить</div>
                      <div id='btn-loader' class=""></div>
                    </div>
                  </button> -->



              </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-12"></div>
    </div>
  </div>

</template>


<script setup>
    import ImagePicker from "@/components/ux/ImagePicker.vue";
    import youtubeLogo from "@/assets/img/platforms/youtube.png";
    import vkLogo from "@/assets/img/platforms/vk.png";
    import yandexLogo from "@/assets/img/platforms/yandex_dzen.png";
    import rutubeLogo from "@/assets/img/platforms/rutube.png";
// import { RouterLink } from "vue-router";
</script>

<script>
export default {
  name: "PageCreateVideo",

  data: () => ({
    isLoad: true,
    isError: false,
    platforms_icon: {
      1: youtubeLogo,
      3: vkLogo,
      4: yandexLogo,
      2: rutubeLogo
    },
    platforms: [],
    video: {
      name: "",
      description: "",
      image: null,
      links: [],
    },
  }),
  created() {
    Promise.all(
      [
        this.getPlatforms()
      ]
    )
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e) => {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
  },
  computed: {

  },
  methods: {
    beforeRender() { },
    getPlatformLink(platformId) {
      // Находим ссылку для конкретной платформы, если она существует
      const link = this.video.links.find(
        (item) => item.id_platform === platformId
      );
      return link ? link.link : ""; // Если ссылка не найдена, возвращаем пустую строку
    },

    setPlatformLink(platformId, newLink) {
      // Находим объект ссылки в видео
      const link = this.video.links.find(
        (item) => item.id_platform === platformId
      );

      if (link) {
        // Если ссылка уже существует, обновляем её значение
        link.link = newLink;
      } else {
        // Если ссылка для платформы ещё не существует, добавляем её
        this.video.links.push({
          link: newLink,
          id_platform: platformId,
        });
      }

      console.log(this.video.links)
    },

    async getPlatforms() {
      // Получаем список платформ из store
      this.platforms = await this.$store.dispatch("getPlatforms");
    },
    async createVideo() {
      const btnText = document.getElementById('btn-text')
      const btnLoader = document.getElementById('btn-loader')
      btnLoader.classList.add('btn-loader')
      btnText.classList.add('hide')

      const result_video = {
        name: this.video.name,
        description: this.video.description,
        image: this.video.image,
        links: this.video.links.map((link) => ({
          id: link.id, // Оставляем ID, если он существует
          link: link.link,
          id_platform: link.id_platform,
        })),
      } 
      const new_video = await this.$store.dispatch('videoCreate', result_video);
      if (new_video){
        this.$router.push({ name: 'PageEdit', params: {id: new_video.id} });
      }

      btnText.classList.remove('hide')
      btnLoader.classList.remove('btn-loader')
    }
  },
}
</script>

<style>

body{
  background-color: #353535!important;
}

.course-info-fields{
  padding: 20px;
  margin-top: 5px;
  background-color: white;
  border-radius: 0 0 8px 8px;
}


.field__right {
    display: flex;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    align-items: center;
}

.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.btns .btn--default {
    margin: 0 5px;
}

.main-container {
    margin-top: 10px;
}

</style>

<style scoped>
.c-filed-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.empty-text {
  font-size: 12px;
  white-space: nowrap;
  color: var(--cozh-grey);
}
</style>

<style scoped>


.c-filed-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.empty-text {
  font-size: 12px;
  white-space: nowrap;
  color: var(--cozh-grey);
}
</style>