import { createStore } from 'vuex'
import axios from 'axios'


export default createStore({
  
  // admin
  // AuFsgbk3crq9

  // state.auth.user.profile.id_role
  // 0 - root (глава цож)
  // 1 - admin (Управляющий УК)
  // 2 - user (Обычный пользователь)
  
  state: {
    // api_endpoint: 'http://127.0.0.1:8000',
    api_endpoint: 'https://api.boondy.link',
    
    main_url: 'https://api.boondy.link',
    front_url: 'https://boondy.link',

    auth: {
      tokens: {
        access: localStorage.getItem('access_token') || null,
        refresh: localStorage.getItem('refresh_token') || null,
      },
      user: {
        profile: JSON.parse(localStorage.getItem('profile')) || null,
      },
    },
    modules: null,
    regions: null,
    unreadMessages: 0,
  },

  getters : {
    // bool статус авторизации
    isLoggedIn: state => !!state.auth.tokens.access,
    // Текущий пользователь
    authUser: state => state.auth.user,
  },
  

  mutations: {

    setTokens(state, {access_token, refresh_token}) {
      state.auth.tokens.access = access_token;
      state.auth.tokens.refresh = refresh_token;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
    },

    setProfile(state, {profile}) {
      state.auth.user.profile = profile;
      localStorage.setItem('profile', JSON.stringify(profile));
    },

    setOrganization(state, {organization}) {
      state.auth.user.organization = organization;
      localStorage.setItem('organization', JSON.stringify(organization));
    },

    logout(state) {

      state.auth.tokens.access = null;
      state.auth.tokens.refresh = null;
      // state.auth.user.profile = null;
      // state.auth.user.organization = null;

      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      // localStorage.removeItem('profile');
      // localStorage.removeItem('organization');
      
      delete axios.defaults.headers.common['Authorization'];
      
    },

    setModules(state, { modules }) {
      state.modules = modules;
    },
    setRegions(state, { regions }) {
      state.regions = regions;
    },
    setUnreadMessages(state, { count }) {
      state.unreadMessages = count;
    },
  },

  actions: {
    // Получить список всех курсов
    async getVideoAll ({state}) {
      const r = await axios.get(state.api_endpoint + '/video/all');
      return r.data;
    },
    async getVideo ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/video/' + param.video_link);
      return r.data;
    },
    async getVideoById ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/video/edit/' + param.video_id);
      return r.data;
    },
    async deleteVideo ({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/video/' + param.video_id);
      return r.data;
    },
    
    
    // Получить детальную информацию о курсе
    async getCourseDetail ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/'  + param.course_id +'/detail');
      return r.data;
    },

    // Получить отзывы о курсе
    async getCourseReview ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/'  + param.course_id +'/reviews');
      return r.data;
    },

    // Получить отзывы о курсе
    async getCourseBreadcrumbs ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/'  + param.course_id +'/breadcrumbs');
      return r.data;
    },

    // Получить спиосок курсов по категории
    async getCoursesCategory ({state}, param) {
      const r = await axios.get(state.api_endpoint + '/category/'  + param.category_shard +'/courses');
      return r.data;
    },

    // Поступить на курс
    async addCourseStudent({state}, param) {
      const r = await axios.post(state.api_endpoint + '/course/add-student', param);
      return r.data;
    },







    // Получить информацию по пользователю
    async getUserProfile ({state}) {
      const r = await axios.get(state.api_endpoint + '/user/profile');
      return r.data;
    },

    // Изменить информацию по пользователю
    async editUserProfile ({state}, param) {
      const r = await axios.post(state.api_endpoint + '/user/profile', param);
      return r.data;
    },

    // Получить курсы пользователя
    async getUserCourses({state}) {
      const r = await axios.get(state.api_endpoint + '/user/courses');
      return r.data;
    },

    // Получить избранные курсы пользователя
    async getCoursesLiked({state}) {
      const r = await axios.get(state.api_endpoint + '/user/courses/liked');
      return r.data;
    },

    // Получить модули курса
    async getCourseModules({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/detail/' + param.course_id + '/modules');
      return r.data;
    },

    // Получить уроки по модулю курса
    async getCourseLessons({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/detail/' + param.course_id  + '/' + param.module_id + '/lessons');
      return r.data;
    },

    // Получить контент урока
    async getLessonContent({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/detail/' + param.lesson_id  + '/content');
      return r.data;
    },

    // Получить список пройденных уроков
    async getCourseProgress({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/lesson/completed-lesson/' + param.course_id);
      return r.data;
    },

    // Добавить урок в список пройденных
    async addLessonCompleted({state}, param) {
      const r = await axios.post(state.api_endpoint + '/course/lesson/completed-lesson',  param);
      return r.data;
    },

    // Удалить урок из списка пройденных
    async delLessonCompleted({state}, param) {
      const r = await axios.delete(state.api_endpoint + '/course/lesson/completed-lesson/' + param.lesson_id);
      return r.data;
    },




    

  // teach

  /// Получить список курсов автора
  async getAuthorCourses({state}) {
    const r = await axios.get(state.api_endpoint + '/teach/courses');
    return r.data;
  },

  /// Создать новый курс
  async addAuthorCourse({state}, param) {
    const r = await axios.post(state.api_endpoint + '/teach/course', param);
    return r.data;
  },

  /// Создать новый модуль
  async addAuthorCourseModule({state}, param) {
    const r = await axios.post(state.api_endpoint + '/teach/course/module', param);
    return r.data;
  },

  /// Создать новый урок
  async addAuthorCourseLesson({state}, param) {
    const r = await axios.post(state.api_endpoint + '/teach/course/lesson', param);
    return r.data;
  },

  /// Добавить файл к уроку
  async addAuthorLessonFile({state}, param) {
    const r = await axios.post(state.api_endpoint + '/teach/course/lesson/file', param);
    return r.data;
  },












    
    // Получить все категории
    async getCategories({state}) {
      const r = await axios.get(state.api_endpoint + '/data/category/all');
      return r.data;
    },

    
    // Отправить курс на модерацию
    async changeCourseStatus({state}, param) {
      const r = await axios.post(state.api_endpoint + '/teach/course/change-status', param);
      return r.data;
    },





    
    // Получить структуру курса (Модули и уроки)
    async saveCourse({state}, param) {
      const r = await axios.post(state.api_endpoint + '/teach/course/save', param);
      return r.data;
    },
    
    // Получить структуру курса (Модули и уроки)
    async getCourseStructure({state}, param) {
      const r = await axios.get(state.api_endpoint + '/course/' + param.course_id  + '/structure');
      return r.data;
    },

     // Получить структуру курса (Модули и уроки) со всеми данными
     async getCourseStructureFull({state}, param) {
      const r = await axios.get(state.api_endpoint + '/teach/course/' + param.course_id  + '/full-structure');
      return r.data;
    },
    














     // Получить структуру курса (Модули и уроки) со всеми данными
    async getPlatforms({state}) {
      const r = await axios.get(state.api_endpoint + '/platform');
      return r.data;
    },

     // Получить структуру курса (Модули и уроки) со всеми данными
    async videoUpdate({state}, param) {
      const r = await axios.patch(state.api_endpoint + '/video/patch', param);
      return r.data;
    },

     // Получить структуру курса (Модули и уроки) со всеми данными
    async videoCreate({state}, param) {
      const r = await axios.post(state.api_endpoint + '/video/create', param);
      return r.data;
    },





     // Получить структуру курса (Модули и уроки) со всеми данными
    async signupUser({state}, param) {
      try {
        const tokenResponse = await axios.post(state.api_endpoint + '/users/create', param);
        if (tokenResponse.status == 201) {
          // Устанавливаем токены
          const access_token = tokenResponse.data.access_token;
          const refresh_token = tokenResponse.data.refresh_token;
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
          this.commit('setTokens', {access_token, refresh_token});
          return true;
        }
      } catch (e) {e}
      this.commit('logout');
      return false;
    },




    // Выход из системы
    async logout({commit}) {
      commit('logout');
      return true;
    },


    // Получить информацию по пользователю
    async loginUser({state}, param) {
      try {
        // Запрос авторизации
        const tokenResponse = await axios.post(state.api_endpoint + '/auth/token', param, {headers:{'Content-Type': 'application/x-www-form-urlencoded'}});
        // Всё прошло нормально
        if (tokenResponse.status == 200) {
          // Устанавливаем токены
          const access_token = tokenResponse.data.access_token;
          const refresh_token = tokenResponse.data.refresh_token;
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
          this.commit('setTokens', {access_token, refresh_token});

          // Информация о пользователе 
          // const meResp = await axios.get(state.api_endpoint + '/user/profile');
          
          // const profile = meResp.data;

          // this.commit('setProfile', {profile});

          return true;
        }
      } catch (e) {e}
      this.commit('logout');
      return false;
    },
    



    // Авторизация
    // async login({commit, state}, param ) {
    //   try {
    //     // Запрос авторизации
    //     const tokenResponse = await axios.post(state.api_endpoint + '/auth/token', param, {headers:{'Content-Type': 'application/x-www-form-urlencoded'}});
    //     // Всё прошло нормально
    //     if (tokenResponse.status == 200) {
    //       // Устанавливаем токены
    //       const access_token = tokenResponse.data.access_token;
    //       const refresh_token = tokenResponse.data.refresh_token;
    //       axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    //       commit('setTokens', {access_token, refresh_token});

    //       // Информация о пользователе 
    //       const meResp = await axios.get(state.api_endpoint + '/lk/profile');
          
    //       const profile = meResp.data.profile;
    //       const organization = meResp.data.organization;

    //       commit('setProfile', {profile});
    //       commit('setOrganization', {organization});

    //       return true;
    //     }
    //   } catch (e) {e}
    //   commit('logout');
    //   return false;
    // },

    // Обновление токенов
    async refresh ({commit, state}) {
      try {
        // Запрос обмена токенов
        const refreshResponse = await axios.post(state.api_endpoint + '/auth/refresh', { refresh_token: state.auth.tokens.refresh }, {headers:{'Content-Type': 'application/x-www-form-urlencoded'}});
        // Всё прошло нормально
        if (refreshResponse.status == 200) {
          // Устанавливаем токены
          const access_token = refreshResponse.data.access_token;
          const refresh_token = refreshResponse.data.refresh_token;
          commit('setTokens', {access_token, refresh_token});
          // Глобально в axios  
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
          return true;
        }
      } catch (e) {e}
      return false;
    },

  },

});
