<template>
  <div v-if="isLoad" class="load-container">
    <div class="custom-loader"></div>
  </div>

  <div v-else>
    <div class="container">

      <div class="main-header">
        <router-link :to="'/'" class="logo-text">
          <h1>Boondy.link</h1>
        </router-link>
        <a class='new-link' @click="logout">Выйти</a>
      </div>

      <router-link class='new-link btn--default' :to="'lk/create'">Создать новую ссылку</router-link>


    </div>

    <ConfirmDialogue ref="confirmDialogue" />

    <div class="container main-content">
      <div class="row video-card" v-for="video in items" :key="video.id">
        <div class="col-md-3 col-xs-12" style="padding: 0;">
          <img class="video-previw" v-if="video.image" :src="main_url + video.image" alt="">
          <img class="video-previw" v-else src="../../assets/img/no_photo.jpg" alt="">
        </div>
        <div class="col-md-4 col-xs-12">
          <h3>{{ video.name }}</h3>
        </div>
        <div class="col-md-2 col-xs-6"><span style="display: flex;align-items: center; justify-content: left; margin-left: 10px;">Просмотров {{ video.click_count_s_link }}</span></div>

        <div class="col-md-3 col-xs-6" >
          <div style="display: flex; justify-content: center; margin-left: 10px;">
            <span :key="video.id"
              style=" display: flex;cursor: pointer;padding: 10px;align-items: center;justify-content: flex-start; cursor: pointer;"
              @click="copySmartLink(video, video.id)">
              <!-- <span class="share-text">{{ copiedStatus[video.id] ? 'Скопировано!' : 'Поделиться' }}</span> -->
              <img style="vertical-align: middle; height: 30px; " src="../../assets/img/send-main.png">
            </span>

            <router-link :key="video.id" style="padding: 10px;" :to="'/lk/edit/' + video.id">
              <img style="vertical-align: middle;height: 30px; cursor: pointer;" src="../../assets/img/pen.svg">
            </router-link>
            <span :key="video.id" style="padding: 10px;" @click="removeVideo(video.id)">
              <img style="vertical-align: middle; height: 30px; cursor: pointer;" src="../../assets/img/remove.svg">
            </span>
          </div>

        </div>



      </div>
    </div>

    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xs-12" style="
    padding: 0;
">
            <b>Техподдержка в телеграм - <a href="https://t.me/boondy_link">@boondy_link</a></b>

          </div>

        </div>
      </div>

    </footer>
  </div>
  <div id="notification" class="notification">
      {{ alert_text }}
    </div>

</template>

<script setup>
import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue'
</script>

<script>
export default {
  name: 'PageHome',
  data: () => ({
    alert_text: '',
    // copiedStatus: [],
    items: [],
    isLoad: true,
    isError: false,
    headerText: null,
    scrollTop: 0,

  }),
  created() {
    Promise.all([this.loadAllCourses()])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e) => {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })
  },
  computed: {
    main_url() { return this.$store.state.main_url; },
    front_url() { return this.$store.state.front_url; }
  },
  methods: {
    beforeRender() { },
    async removeVideo(video_id) {
      // this.$refs.confirmDialogue.show({message:"Вы действительно хотите удалить данную мультиссылку?", okButton: 'Подтвердить'});


      const confirm = await this.$refs.confirmDialogue.show({
        message: 'Вы уверены, что хотите удалить данную мультиссылку?',
        okButton: 'Удалить',
      });
      if (!confirm) { return; }
      const btnText = document.getElementById('btn-text')
      const btnLoader = document.getElementById('btn-loader')
      btnLoader.classList.add('btn-loader')
      btnText.classList.add('hide')


      try {
        await this.$store.dispatch('deleteVideo', { video_id: video_id });
        Promise.all([this.loadAllCourses()])
      } catch (e) {
        // this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });
      }


      btnText.classList.remove('hide')
      btnLoader.classList.remove('btn-loader')

      this.alert_text = "Мультиссылка успешно удалена!"
      const notification = document.getElementById('notification');
      notification.classList.add('show');
      // Убираем уведомление через 5 секунд
      setTimeout(() => {
        notification.classList.remove('show');
      }, 5000);

    },
    async copySmartLink(video) {
      // await navigator.clipboard.writeText(this.front_url + '/' + this.video.small_link)
      // this.copy_text = 'Скопировано!';

      await window.navigator.clipboard
        .writeText(this.front_url + '/' + video.small_link)
        .then(() => this.copy_text = 'Скопировано!')
        .catch((err) => console.error(err));

      // this.copiedStatus[video.id] = true;

      // // Через 2 секунды возвращаем состояние обратно на "Поделиться"
      // setTimeout(() => {
      //   this.copiedStatus[video.id] = false;
      // }, 2000);

      this.alert_text = "Скопировано!"
      const notification = document.getElementById('notification');
      notification.classList.add('show');
      // Убираем уведомление через 5 секунд
      setTimeout(() => {
        notification.classList.remove('show');
      }, 5000);



    },
    async loadAllCourses() {
      this.items = await this.$store.dispatch('getVideoAll');
      // this.copiedStatus = Array(this.items.length).fill(false);
    },
    handleScroll() {
      this.scrollTop = window.scrollY;
    },

    async logout() {
      this.$router.push({ name: 'PageLogin' }).then(() => {
        document.querySelectorAll('body')[0].classList.remove('overflow');
        this.$store.dispatch('logout');
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },

}
</script>

<style>
body {
  min-width: 330px;
}

.main-content {
  margin-top: 15px;
}

.new-link {
  display: inline-block;
  margin-top: 10px;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.video-card {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  background-color: white;
  color: black;
  margin-bottom: 15px;
  justify-content: space-between;
}

.video-card img.video-previw {
  /* width: 300px; */
  aspect-ratio: 16 / 9;
  display: block;
  /* Устанавливаем соотношение сторон 16:9 */
  object-fit: cover;
  /* Покрываем область, сохраняя пропорции */
  /* margin-right: 20px; */
}

.video-card h3 {
  padding: 10px 10px 0 10px;
}

tr,
td {
  border-bottom: none;
}

.main-header {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.logo-text {}

footer {
  color: white;
  /* background-color: var(--byskill-purple); */
}


.notification {
  position: fixed;
  bottom: -100px;
  /* Изначально скрыто за пределами экрана */
  right: 20px;
  background-color: #fff;
  color: #000;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: bottom 0.5s ease-in-out;
  opacity: 0;
  z-index: 1000;
}

.notification.show {
  bottom: 20px;
  /* Появление уведомления */
  opacity: 1;
}
</style>