import * as Vue from 'vue';

// Основной компонент приложения
import App from './App.vue';

// Библиотеки
// import moment from 'moment';
import axios from 'axios';
import moment from 'moment';
import mask from 'vue-the-mask'; 

// Файлы
import helpers from './helpers';
import router from './router';
import store from './store';

// Стили
import './assets/css/main.css';
import './assets/css/custom.css';
import './assets/css/grid.css';
import './assets/css/utils.css';
import './assets/css/calendar.css';

// Шаблоны
import MainLayout from "./layouts/Main";
import EmptyLayout from "./layouts/Empty";

// Глобальные настройки moment
moment.locale('ru');


// Берём access_token из localStorage
const access_token = localStorage.getItem('access_token');
// Если access_token есть в localStorage - ставим в заголовки по умолчанию
if (access_token) { axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token }

// Отлавливаем все запросы через axios
axios.interceptors.response.use(
  (res) => { return res },
  async (error) => {
    // Запрос на котором произошла ошибка
    const originalRequest = error.config;
    // access_token не действительный 
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      // Если 401 ошибка пришла от метода обновления токена
      if(originalRequest.url == store.state.api_endpoint + '/auth/refresh' || originalRequest.url == store.state.api_endpoint + '/auth/token') {
        // Выходим из системы
        await router.push('/login');
        store.dispatch('logout');
        return;
      } else {
        // 401 ошибка от любого другого метода
        const refresh = await store.dispatch('refresh');
        if(refresh) {
          originalRequest.headers.Authorization = axios.defaults.headers.common.Authorization;
          // Повторяем предыдущий запрос
          return axios(originalRequest);
        } else {
          // Не работает метод обновления токена (не 401) - выходим
          // Выходим из системы
          await router.push('/login');
          store.dispatch('logout');
          return;
        }
      }
    }else if (error.response.status === 403) {
      // alert("У вас нет доступа!")
      await router.push('/profile');
      return;
    }
    throw error;
  }
);


// Приложение
const app = Vue.createApp(App);

// Регистрируем глобальные свойства
app.config.globalProperties.$helpers = helpers;
// app.config.globalProperties.$moment = moment;
app.config.globalProperties.axios = axios;

// Регистрируем шаблоны
app.component("main-layout", MainLayout);
app.component("empty-layout", EmptyLayout);


// Регистрируем библиотеки
app.use(router);
app.use(store);
app.use(mask);

app.mount('#app');