<template>
  <div class="auth">
    <div class="auth__form">
      <h1 class="auth__title">Войти</h1>

      <div class="field">
          <div class="field__right">
          <input class="form-control" type="email" placeholder="Email" v-model="user.email">
          </div>
      </div>
      
      <div class="field">
          <div class="field__right">
          <input class="form-control" type="password" placeholder="Пароль" v-model="user.password">
          </div>
      </div>

      <p v-if="errors" class="error">{{ errors }}</p>

      <div class="btns">
          <!-- <button class='btn--default' @click="logIn(user.email, user.password)">Войти</button> -->


          <button class="btn--default" @click="logIn(user.email, user.password)">
            <div class="btn-content" style="display: flex; justify-content: center; align-items: center;">
              <div id='btn-text' class="btn-text">Войти</div>
              <div id='btn-loader' class=""></div>
            </div>
          </button>


          <router-link :to="'/signup'">Зарегистрироваться</router-link>  
      </div>
    </div>
  </div>
</template>


<script setup>
</script>


<script>
  
  export default {
    name: 'PageLogin',
    
    // data: () => ({
    //   errorMessage: '',
    //   form: {
    //     username: {
    //       label: 'Ваш Email',
    //       // value: 'test2@test.ru',
    //       required: {
    //         status: true,
    //         message: 'Заполните поле',
    //       },
    //       validate: [
    //         {regexp: /.{5,}/, msg: 'Минимум 5 символов'}, 
    //       ],
    //       error: {
    //         status: false,
    //         message: null,
    //       }
    //     },
    //     password: {
    //       label: 'Пароль',
    //       value: 'T/l6^D=',
    //       required: {
    //         status: true,
    //         message: 'Заполните поле',
    //       },
    //       validate: [
    //         {regexp: /.{6,}/, msg: 'Минимум 6 символов'}, 
    //       ],
    //       error: {
    //         status: false,
    //         message: null,
    //       }
    //     },
    //   }
    // }),
    // watch: {
    //   // if (isAuth) {
    //   //       this.$router.push({ name: 'PageHome' });
    //   //     }
    // //   'form.username.value': function () {
    // //     this.form.username.error.status = false;
    // //   },
    // //   'form.password.value': function () {
    // //     this.form.password.error.status = false;
    // //   },
    // },


    data: () => ({
      user: {
          email: '',
          password: ''
        },
      errors: ''
    }),

    methods: {
      async logIn (username, password){
        this.errors = '';
        const btnText = document.getElementById('btn-text')
        const btnLoader = document.getElementById('btn-loader')
        btnLoader.classList.add('btn-loader')
        btnText.classList.add('hide')
        


        // Валидация формы, готовность к отправке
        let isReady = true; //this.$helpers.validateFields(this.form);
        if(isReady) {
          const isAuth = await this.$store.dispatch('loginUser', { username: username, password: password });

          if (isAuth) {
            this.$router.push({ name: 'PageHome' });
          } else {
            this.errors = 'Неверный логин или пароль';
          }
        }
        
        btnText.classList.remove('hide')
        btnLoader.classList.remove('btn-loader')
      }
    },
  }
</script>

<style scoped>
.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 10px;
  }
  .auth {
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .auth__form {
    color: black;
    background-color: var(--cozh-white);
    padding: 40px;
    width: 550px;
    box-shadow: 0px 25px 75px rgba(11, 40, 54, 0.15);
    border-radius: 12px;
  }

  .auth__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    .auth__form {
      padding: 20px;
    }
    .auth__title {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  .field {
    margin: 10px;
  }

  .btns {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btns button{
    margin-bottom: 10px;
  }
  .btn-loader {
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0)!important;
  }
</style>