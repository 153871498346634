<template>
  <div v-if="isLoad" class="load-container" ><div class="custom-loader"></div></div> 
  <div v-else class="container center">
    <div class="hint" v-if="video.id">
      <!-- Данные отсутствуют -->
      <div class="row main-container">
        <div class="col-md-3 col-xs-12"></div>
        <div class="col-md-6 col-xs-12" style="margin-top: 5px">
          <img class="video-previw" v-if="video.image" :src="main_url + video.image" alt="">
          <img class="video-previw" v-else src="../../assets/img/no_photo.jpg" alt="">
          <!-- <ImagePicker v-model="video.image" /> -->
          <div class="course-info-fields">
            <h1>{{video.name}}</h1>
            <hr>
            <!-- Динамическое отображение полей для каждой платформы -->
            <div v-for="platform in platforms" :key="platform.id" class="field">
              <div v-if="getPlatformLink(platform.id)" class="field__right">
                <div
                  class="pl-logo"
                  :style="{
                    backgroundImage: 'url(' + platforms_icon[platform.id] + ')'
                  }"
                ></div>
                <span class="pl-logo-name">{{ platform.name }}</span>

                <!-- Используем v-bind для начального значения и @input для обновления -->
                <!-- <input
                  class="form-control"
                  type="text"
                  :placeholder="'Ссылка на ' + platform.name"
                  :value="getPlatformLink(platform.id)"
                  @input="setPlatformLink(platform.id, $event.target.value)"
                /> -->
                <a :href="getPlatformLink(platform.id)" class='btn--default' @click="saveCourse">Смотреть</a>
              </div>
            </div>


            <!-- <div class="field__right">
              <div class="pl-logo" :style="{ backgroundImage: 'url(' + platforms_icon.vk + ')' }"></div>
              <span class=pl-logo-name>Вк Видео</span>
              <button class='btn--default' @click="saveCourse">Смотреть</button>
            </div> -->



            <!-- <div class="field">
                  <div class="field__right">
                    <span class="video-name">{{ video.name }}</span>
                    <input class="form-control" type="text" placeholder="Название" v-model="video.name"> 
                  </div>
              </div> -->
              
              <!-- <div class="field">
                <div class="field__right">
                  <div class="pl-logo" :style="{ backgroundImage: 'url(' + platforms_icon.vk + ')' }"></div>
                  <span class=pl-logo-name>Вк Видео</span>
                   <input class="form-control" type="text" placeholder="Ссылка на видео YouTube" v-model="video.vk_link"> 
                  <button class='btn--default' @click="saveCourse">Смотреть</button>
                </div>
              </div> -->
              <!-- <div class="btns">
                  <button class='btn--default' @click="saveCourse">Создать мульти-ссылку</button>
              </div> -->
          </div>
        </div>
        <div class="col-md-3 col-xs-12"></div>
    </div>
    </div>

    <!-- </div> -->
    <template v-else>
      <div class="row">
        <h1>Такой страницы не существует! </h1>
      </div>
    </template>

    <a href="/" class="logo-emblem">
      Сделано с помощью
      <span>boondy.link</span>
    </a>
  </div>

</template>


<script setup>
    // import ImagePicker from '@/components/ux/ImagePicker.vue';
    import youtubeLogo from "@/assets/img/platforms/youtube.png";
    import vkLogo from "@/assets/img/platforms/vk.png";
    import yandexLogo from "@/assets/img/platforms/yandex_dzen.png";
    import rutubeLogo from "@/assets/img/platforms/rutube.png";
</script>

<script>
export default {
  name: 'PageHome',
  data: () => ({
    isLoad: true,
    platforms_icon: {
      1: youtubeLogo,
      3: vkLogo,
      4: yandexLogo,
      2: rutubeLogo
    },
    platforms: [],
    video: {
      name: "",
      description: "",
      image: null,
      links: [],
    },
  }),
  computed: {
    video_link() { return this.$route.params.link; },
      main_url() { return this.$store.state.main_url; }
  },
  created() {
    Promise.all(
      [
        this.getVideo(this.video_link),
        this.getPlatforms()
      ]
    )
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
        document.title = document.title + ' "' + this.video.name + '" на Boondy.link'
      })
      .catch((e) => {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      })



  },
  methods: {
    beforeRender() { },
    getPlatformLink(platformId) {
      // Находим ссылку для конкретной платформы, если она существует
      const link = this.video.links.find(
        (item) => item.id_platform === platformId
      );
      return link ? link.link : ""; // Если ссылка не найдена, возвращаем пустую строку
    },
    async getPlatforms() {
      // Получаем список платформ из store
      this.platforms = await this.$store.dispatch("getPlatforms");
    },
    async getVideo(video_link) {
      this.platforms = await this.$store.dispatch('getPlatforms');
      this.video = await this.$store.dispatch('getVideo', { 'video_link': video_link });
      // this.breadcrumbs = this.breadcrumbs.concat(await this.$store.dispatch('getCourseBreadcrumbs', {course_id: course_id}));
    }
  },
}
</script>

<style>

body{
  background-color: #353535!important;
  color: white!important;
}

.course-info-fields{
  padding: 20px;
  margin-top: 5px;
  background-color: white;
  color: black ;
  border-radius: 0 0 8px 8px;
}


.field__right {
    display: flex;
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.btns {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-around;
}
.btns .btn--default {
    margin: 0 5px;
}

.main-container {
    margin-top: 10px;
}

.video-name {
  font-size: 25px;
}

.video-previw {
  width: 100%;
  aspect-ratio: 16 / 9; /* Устанавливаем соотношение сторон 16:9 */
  object-fit: cover; /* Покрываем область, сохраняя пропорции */
  margin-right: 20px;
}
.course-info-fields h1 {
  margin-bottom: 10px;
}
</style>

<style scoped>
.c-filed-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.empty-text {
  font-size: 12px;
  white-space: nowrap;
  color: var(--cozh-grey);
}
</style>

<style scoped>


.c-filed-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.empty-text {
  font-size: 12px;
  white-space: nowrap;
  color: var(--cozh-grey);
}

.logo-emblem{
  margin: 20px auto;
  display: block;
  line-height: 1.35;
  text-align: center;
  padding: 5px;
  width: 300px;
  height: 100px;
  border: 1px solid white;
  border-radius: 0 20px 20px 20px;
  font-weight: 400;
}

.logo-emblem span{
  font-size: 2.5rem;
  font-weight: 800;
}

</style>