<template>
  <div class="auth">
    <div class="auth__form">
      <h1 class="auth__title">Регистрация</h1>

      <div class="field">
        <div class="field__right">
          <input
            class="form-control"
            type="email"
            placeholder="Email"
            v-model="user.email"
          />
        </div>
        <p v-if="errors.email" class="error">{{ errors.email }}</p>
      </div>

      <div class="field">
        <div class="field__right">
          <input
            class="form-control"
            type="password"
            placeholder="Пароль"
            v-model="user.password"
          />
        </div>
        <p v-if="errors.password" class="error">{{ errors.password }}</p>
      </div>

      <div class="field">
        <div class="field__right">
          <input
            class="form-control"
            type="password"
            placeholder="Пароль ещё раз"
            v-model="user.password2"
          />
        </div>
        <p v-if="errors.password2" class="error">{{ errors.password2 }}</p>
      </div>

      <div class="btns">
        <button class="btn--default" @click="signUp">Зарегистрироваться</button>
        <router-link :to="'/login'">Войти</router-link>
      </div>
    </div>
  </div>
</template>


<script setup>
  // import TelegramLogin from "@/components/trash/TelegramLogin.vue"
</script>


<script>
  
  export default {
    name: 'PageLogin',
    
    // data: () => ({
    //   errorMessage: '',
    //   form: {
    //     username: {
    //       label: 'Ваш Email',
    //       // value: 'test2@test.ru',
    //       required: {
    //         status: true,
    //         message: 'Заполните поле',
    //       },
    //       validate: [
    //         {regexp: /.{5,}/, msg: 'Минимум 5 символов'}, 
    //       ],
    //       error: {
    //         status: false,
    //         message: null,
    //       }
    //     },
    //     password: {
    //       label: 'Пароль',
    //       value: 'T/l6^D=',
    //       required: {
    //         status: true,
    //         message: 'Заполните поле',
    //       },
    //       validate: [
    //         {regexp: /.{6,}/, msg: 'Минимум 6 символов'}, 
    //       ],
    //       error: {
    //         status: false,
    //         message: null,
    //       }
    //     },
    //   }
    // }),
    // watch: {
    //   // if (isAuth) {
    //   //       this.$router.push({ name: 'PageHome' });
    //   //     }
    // //   'form.username.value': function () {
    // //     this.form.username.error.status = false;
    // //   },
    // //   'form.password.value': function () {
    // //     this.form.password.error.status = false;
    // //   },
    // },


    data: () => ({
      user: {
        email: '',
        password: '',
        password2: ''
      },
      errors: {
        email: '',
        password: '',
        password2: ''
      }
    }),

    methods: {
       validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
      },

      validateForm() {
        this.errors.email = '';
        this.errors.password = '';
        this.errors.password2 = '';

        if (!this.user.email) {
          this.errors.email = 'Email обязателен.';
        } else if (!this.validateEmail(this.user.email)) {
          this.errors.email = 'Неверный формат email.';
        }

        if (!this.user.password) {
          this.errors.password = 'Пароль обязателен.';
        } else if (this.user.password.length < 6) {
          this.errors.password = 'Пароль должен быть не менее 6 символов.';
        }

        if (this.user.password2 !== this.user.password) {
          this.errors.password2 = 'Пароли не совпадают.';
        }

        return !this.errors.email && !this.errors.password && !this.errors.password2;
      },
      async signUp() {
        if (!this.validateForm()) {
          return;
        }

        // Если валидация успешна
        try {
          const isAuth = await this.$store.dispatch('signupUser', { email: this.user.email, password: this.user.password });
          if (isAuth) {
            console.log(isAuth);
            this.$router.push({ name: 'PageHome' });
          } else {
            this.errors.email = 'Ошибка регистрации.';
          }
        } catch (e) {
          this.errors.email = 'Ошибка при регистрации.';
        }
      }
    },
  }
</script>

<style scoped>
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 10px;
  }
  .auth {
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .auth__form {
    color: black;
    background-color: var(--cozh-white);
    padding: 40px;
    width: 550px;
    box-shadow: 0px 25px 75px rgba(11, 40, 54, 0.15);
    border-radius: 12px;
  }

  .auth__title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 10px;
  }

  @media (max-width: 767px) {
    .auth__form {
      padding: 20px;
    }
    .auth__title {
      font-size: 20px;
      margin-bottom: 15px;
    }
  }

  .field {
    margin: 10px;
  }

  .btns {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .btns button{
    margin-bottom: 10px;
  }
</style>