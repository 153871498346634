<template>
  <div v-if="isLoad" class="load-container">
    <div class="custom-loader"></div>
  </div>
  <div v-else class="container center">

    <div class="container">
      <div class="main-header">
        <router-link :to="'/'"><h1>Boondy.link</h1></router-link>
        <a class='new-link' @click="logout">Выйти</a>
      </div>
    </div>

    <div v-if="video.id" class="row main-container">
      <div class="col-md-3 col-xs-12"></div>
      <div class="col-md-6 col-xs-12" style="margin-top: 5px">
        <!-- Выбор изображения -->
        <ImagePicker v-model="video.image" />

        <div class="course-info-fields">
          <!-- Название видео -->
          <div class="field">
            <div class="field__right">
              <input class="form-control" type="text" placeholder="Название" v-model="video.name" />
            </div>
          </div>

          <!-- Динамическое отображение полей для каждой платформы -->
          <div v-for="platform in platforms" :key="platform.id" class="field">
            <div class="field__right">
              <div class="pl-logo" :style="{
                backgroundImage: 'url(' + platforms_icon[platform.id] + ')'
              }"></div>
              <span class="pl-logo-name">{{ platform.name }}</span>

              <!-- Используем v-bind для начального значения и @input для обновления -->
              <input class="form-control" type="text" :placeholder="'Ссылка на ' + platform.name"
                :value="getPlatformLink(platform.id)" @input="setPlatformLink(platform.id, $event.target.value)" />
            </div>
          </div>

          <!-- Кнопка сохранения -->
          <div class="btns">
            <button class="btn--default" @click="saveCourse">
              <div class="btn-content" style="
              display: flex;
              justify-content: center;
              align-items: center;
            ">
                <div id='btn-text' class="btn-text">Сохранить</div>
                <div id='btn-loader' class=""></div>
              </div>
              <!-- <div class="btn--loader"></div>   -->
            </button>


            <span 
            style="display: flex;
              /* vertical-align: middle; */
              margin-left: 10px;
              align-content: center;
              flex-direction: row;
              align-items: center;
              cursor: pointer;" 
            @click="copySmartLink">
            <img style="
              vertical-align: middle;
              height: 30px;
              " src="../../assets/img/send-main.png">
              <span class="share-text">{{ copy_text }}</span>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12"></div>
    </div>

    <template v-else>
      <div class="row">
        <h1>Такой страницы не существует! </h1>
      </div>
    </template>

    <div id="notification" class="notification">
      {{ alert_text }}
    </div>
  </div>

</template>

<script setup>
import ImagePicker from "@/components/ux/ImagePicker.vue";
import youtubeLogo from "@/assets/img/platforms/youtube.png";
import vkLogo from "@/assets/img/platforms/vk.png";
import yandexLogo from "@/assets/img/platforms/yandex_dzen.png";
import rutubeLogo from "@/assets/img/platforms/rutube.png";
</script>

<script>
export default {
  name: "PageEditVideo",

  data: () => ({
    isLoad: true,
    alert_text: '',
    copy_text: "Поделиться",
    isError: false,
    platforms_icon: {
      1: youtubeLogo,
      3: vkLogo,
      4: yandexLogo,
      2: rutubeLogo
    },
    platforms: [],
    video: {
      name: "",
      description: "",
      image: null,
      links: [],
    },
  }),

  created() {
    Promise.all([this.getVideo(this.video_id), this.getPlatforms()])
      .then(() => {
        this.isLoad = false;
      })
      .catch((e) => {
        console.log(e);
        this.isError = true;
        this.isLoad = false;
      });
  },

  computed: {
    video_id() {
      return this.$route.params.id;
    },
    main_url() { return this.$store.state.main_url; },
    front_url() { return this.$store.state.front_url; }
  },

  methods: {
    async getVideo(video_id) {
      // Получение данных видео
      this.video = await this.$store.dispatch("getVideoById", {
        video_id: video_id,
      });

      // Если путь к изображению указан, добавляем к нему базовый URL
      if (this.video.image) {
        this.video.image = this.main_url + this.video.image;
      }
    },

    async getPlatforms() {
      // Получаем список платформ из store
      this.platforms = await this.$store.dispatch("getPlatforms");
    },

    getPlatformLink(platformId) {
      // Находим ссылку для конкретной платформы, если она существует
      const link = this.video.links.find(
        (item) => item.id_platform === platformId
      );
      return link ? link.link : ""; // Если ссылка не найдена, возвращаем пустую строку
    },

    setPlatformLink(platformId, newLink) {
      // Находим объект ссылки в видео
      const link = this.video.links.find(
        (item) => item.id_platform === platformId
      );

      if (link) {
        // Если ссылка уже существует, обновляем её значение
        link.link = newLink;
      } else {
        // Если ссылка для платформы ещё не существует, добавляем её
        this.video.links.push({
          link: newLink,
          id_platform: platformId,
        });
      }

      console.log(this.video.links)
    },
    async copySmartLink() {
      // await navigator.clipboard.writeText(this.front_url + '/' + this.video.small_link)
      // this.copy_text = 'Скопировано!';

      await window.navigator.clipboard
        .writeText(this.front_url + '/' + this.video.small_link)
        .then(() => this.copy_text = 'Скопировано!')
        .catch((err) => console.error(err));

    },
    async saveCourse() {
      const btnText = document.getElementById('btn-text')
      const btnLoader = document.getElementById('btn-loader')
      btnLoader.classList.add('btn-loader')
      btnText.classList.add('hide')
      
      // Подготовка данных для отправки в нужном формате
      const videoToSend = {
        id: this.video.id,
        name: this.video.name,
        description: this.video.description,
        image: this.video.image,
        links: this.video.links.map((link) => ({
          id: link.id, // Оставляем ID, если он существует
          link: link.link,
          id_platform: link.id_platform,
        })),
      };

      // Отправка данных на сервер
      this.video = await this.$store.dispatch("videoUpdate", videoToSend);
      if (this.video.image) {
        this.video.image = this.main_url + this.video.image;
        this.alert_text = "Успешно сохранено!"

      } else {
        this.alert_text = "Произошла ошибка при сохранении!"
      }

      btnText.classList.remove('hide')
      btnLoader.classList.remove('btn-loader')


      const notification = document.getElementById('notification');
      notification.classList.add('show');
      // Убираем уведомление через 5 секунд
      setTimeout(() => {
        notification.classList.remove('show');
      }, 5000);
    },
  },
};
</script>
<style scoped>
.btns {
  justify-content: center;
}

.notification {
  position: fixed;
  bottom: -100px;
  /* Изначально скрыто за пределами экрана */
  right: 20px;
  background-color: #fff;
  color: #000;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: bottom 0.5s ease-in-out;
  opacity: 0;
  z-index: 1000;
}

.notification.show {
  bottom: 20px;
  /* Появление уведомления */
  opacity: 1;
}
</style>
